import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row, Alert, Table } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCanvas } from "../../context/CanvasContext";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setCreateTemplateDetails,
  getTemplate,
  updateTemplate,
  getTemplates,
  showLoader,
  updateDynamicTemplate
} from "../../redux/actions/templateActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import { getQRFieldValidations } from "../../redux/actions/qrSpecificationActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "../../utils/errorMessages";
let createTemplateSchema = yup.object().shape({
  cpiLength: yup
    .number()
    .typeError("Credit party identifier max length is required")
    .min(yup.ref('cpiMinLength'), 'Cannot be less than min value')
    .max(25, "Maximum value for credit party identifier length is 25")
    .required("Credit party identifier max length is required"),
  cpiMinLength: yup
    .number()
    .typeError("Credit party identifier min length is required")
    .min(1, "Minimum value for credit party identifier length is 1")
    .max(25, "Maximum value for credit party identifier length is 25")
    .required("Credit party identifier min length is required"),
  cpnLength: yup
    .number()
    .typeError("Credit party name max length is required")
    .min(yup.ref('cpnMinLength'), 'Cannot be less than min value')
    .max(25, "Maximum value for credit party name length is 25")
    .required("Credit party name max length is required"),
  cpnMinLength: yup
    .number()
    .typeError("Credit party name max length is required")
    .min(1, "Minimum value for credit party name length is 1")
    .max(25, "Maximum value for credit party name length is 25")
    .required("Credit party name min length is required"),
  displayCPNInTemplate: yup.boolean().default(false),
  market: yup.string().typeError("Market is required").required("Market is required"),
  language: yup.string().nullable()
    .when("market", {
      is: (market) => market !== "general",
      then: yup
        .string()
        .required("Language is required")
    }
    ),
  isDynamic: yup.boolean(),
  cpnFormatAZ: yup.boolean(),
  cpnFormat09: yup.boolean(),
  cpnFormatSpecial: yup.boolean(),
  cpiFormatAZ: yup.boolean(),
  cpiFormat09: yup.boolean(),
  cpnFormatAny: yup.boolean(),
});

 
const CreateTempateModal = ({
  show,
  toggleShow,
  setCreateTemplateData,
  selectedMarket,
  getTemplateList,
  groups,
  market,
  isEditFlow,
  template,
  getTemplate,
  languages = [],
  showLoader,
  isSuperAdmin,
  updateTemplate,
  getQRFieldValidations,
  updateDynamicTemplate,
  openSaveModal,
  marketOptions,
  receiveMarketData,
}) => {

  const history = useHistory();
  const { initializeEditor } = useCanvas();
  const [disabledMarket, setDisabledMarket] = useState(false);
  const [originalTemplate, setOriginalTemplate] = useState(false);
  const [langageOptions, setLanguageOptions] = useState([]);
  const [disableLanguage, setDisableLanguage] = useState(true);
  const [marketSelected, setMarketSelected] = useState(false);
  const [anyCharSelected, setAnyCharSelected] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    content: ""
  });

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disableIsDynamicCheckBox, setDisableIsDynamicCheckBox] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      additionalFields: []
    },
    resolver: yupResolver(createTemplateSchema),
    mode: "onChange",
  });

  const { fields } = useFieldArray({
    control,
    name: "additionalFields",
  });
  const watchFieldArray = watch("additionalFields");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    try {
      if (show) {
        let payload = null;
        setLanguageOptions(languages);
        if (isEditFlow && template) {
          // edit flow
          //disable market for edit
          setDisabledMarket(true);
          let detailedTemplate = null;
          async function getTemplateAsync() {
            showLoader(true);
            setMarketSelected(true);
            detailedTemplate = await getTemplate(template.id);
            setOriginalTemplate(detailedTemplate.data);
            const validations = detailedTemplate.data.validations;
            
            payload = {
              cpiLength: validations.cpiLength,
              cpnLength: validations.cpnLength,
              cpiMinLength: validations.cpiMinLength,
              cpnMinLength: validations.cpnMinLength,
              language: template.language,
              market: template.market,
              cpnFormatAZ: validations.cpnFormat.hasAlphabets,
              cpnFormat09: validations.cpnFormat.hasNumbers,
              cpnFormatSpecial: validations.cpnFormat.hasSpecialChars,
              cpnFormatAny: validations.cpnFormat.hasAnyChars,
              cpiFormatAZ: validations.cpiFormat.hasAlphabets,
              cpiFormat09: validations.cpiFormat.hasNumbers,
              isDynamic: detailedTemplate.data.isDynamic,
              additionalFields: detailedTemplate.data.additionalFields ? detailedTemplate.data.additionalFields : [],
              displayCPNInTemplate: detailedTemplate.data.displayCPNInTemplate
            }
            setAnyCharSelected(validations.cpnFormat.hasAnyChars)

            setDisableLanguage(false);

            setDisableIsDynamicCheckBox(true);

            if (payload.isDynamic) {
              setDisableLanguage(true);
              payload.displayCPNInTemplate = true;
            }

            if (!isSuperAdmin) {
              payload = { ...payload, market };
              setLanguageOptions(languages.filter(x => x.market === market));
            } else {
              setLanguageOptions(languages.filter(x => x.market === template.market));
            }
            const requiredFieldList = await fetchQRValidations(template.market, true);
            
            if (payload.market !== "general") {
              // do a comparision with the fetched validation for the market and whats saved in the template
              let templateAdditionalFields = payload.additionalFields;
              let finalFieldsList = [];
              let missingFields = [];
              
              if (requiredFieldList.length > 0) {
                requiredFieldList?.forEach(field => {
                  let index = templateAdditionalFields.findIndex(x => x.mpaKey === field.parentFieldKey);
                  
                  const mappedValidation = mapValidation(field.validation);
                  if (index > -1) {
                    finalFieldsList.push({ ...templateAdditionalFields[index], mpaKey: field.parentFieldKey, fieldName: field.fieldName, lengthMin: field.validation.lengthMin, lengthMax: field.validation.lengthMax, required: field.validation.required, defaultValue: field.defaultValue, validation: mappedValidation })
                  } else {
                    missingFields.push({ ...field, fieldName: field.fieldName, lengthMin: field.validation.lengthMin, lengthMax: field.validation.lengthMax, required: field.validation.required, validation: mappedValidation, requiresUserInput: (field.validation.required === true && field.defaultValue === null), mpaKey: field.parentFieldKey })
                  }
                })
              }

              reset({ ...payload, additionalFields: finalFieldsList.concat(missingFields) });
             
            } else {
              reset(payload);
            }
            showLoader(false);
          }
          getTemplateAsync();
          // end of edit flow
        } else {
          // Create flow
          payload = {
            cpiLength: null,
            cpnLength: null,
            cpiMinLength: null,
            cpnMinLength: null,
            language: "",
            cpnFormatAZ: false,
            cpnFormat09: false,
            cpnFormatSpecial: false,
            cpnFormatAny: false,
            cpiFormatAZ: false,
            cpiFormat09: false,
            additionalFields: []
          };

          setDisabledMarket(false);
          setMarketSelected(false);
          setDisableLanguage(true);
          setDisableNextButton(true);
          setDisableIsDynamicCheckBox(false);
          if (!isSuperAdmin) {
            payload = { ...payload, market };
            fetchQRValidations(market, true);
            setDisabledMarket(true);
            setDisableLanguage(false);
            setMarketSelected(true);
          }
          reset(payload);
          // end of create flow
        }
      }
    } catch (e) {
      toast.error(SOMETHING_WENT_WRONG)
    }

  }, [reset, groups, setLanguageOptions, languages, market, isEditFlow, template, getTemplate, isSuperAdmin, showLoader, show]);

  useEffect(() => {
    receiveMarketData();
  }, [show])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'market') {
        const filtered = languages.filter(x => x.market === value.market);
        setLanguageOptions(filtered);
        if (value.market === null || value.market === "") {
          setDisableLanguage(true);
          setMarketSelected(false);
        } else {
          setMarketSelected(true);
          setDisableLanguage(false);
          fetchQRValidations(value.market, true);
        }
      } else if (name === "cpnFormatAZ" || name === "cpnFormat09" || name === "cpnFormatSpecial" || name === "cpnFormatAny") {
        // triggger added to clear the max value error (vice versa)'
        trigger(["cpnFormatAZ", "cpnFormat09", "cpnFormatSpecial", "cpnFormatAny"])
      } else if (name === "cpiFormatAZ" || name === "cpiFormat09") {
        trigger(["cpiFormatAZ", "cpiFormat09"])
      }
      if (name !== null && name !== undefined && name.includes("requiresUserInput")) {
        const myRegexp = /\.(\d+)\./;
        const match = myRegexp.exec(name);
        const toBeUpated = "additionalFields" + match[0] + "displayInTemplate";
        if (value.additionalFields[match[1]].requiresUserInput === false) {
          setValue(toBeUpated, false)
        }
      }

    });
    return () => subscription.unsubscribe();
  }, [watch, languages, setLanguageOptions, setDisableLanguage, trigger]);

  const fetchQRValidations = async (market, isTemplateCreate = false) => {
    showLoader(true);
    try {
      const result = await getQRFieldValidations(market, isTemplateCreate);
      const rawParameters = result.data;
      if (rawParameters.length > 2) {
        setShowErrorMessage({ show: false, content: "" })
        setDisableNextButton(false);
        // reomve and add to the front
        const mpaRequiredKeys = [
          "creditpartyname", "creditpartyidentifier", "trxcode"
        ]
        const mainParameters = rawParameters.filter(x => mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));
        const otherParameters = rawParameters.filter(x => !mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));

        if (mainParameters.length < mpaRequiredKeys.length - 1) {
          setMarketSelected(false);
          setDisableNextButton(true);
          setShowErrorMessage({ show: true, content: "Invalid QR Specification" });
          setDisableLanguage(true);
          showLoader(false);
          return;
        }
        const cpnObj = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[0])[0];
        const cpiObj = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[1])[0];
        const cpiValidations = cpiObj.validation
        const cpnValidations = cpnObj.validation

        let cpiMaxLengthSpec = cpiValidations.lengthMax;
        let cpiMinLengthSpec = cpiValidations.lengthMin;
        let cpnMaxLengthSpec = cpnValidations.lengthMax;
        let cpnMinLengthSpec = cpnValidations.lengthMin;

        let cpiMinLengthYupObject = null;
        let cpiMaxLengthYupObject = null;
        let cpnMaxLengthYupObject = null;
        let cpnMinLengthYupObject = null;


        // add validations for the length for CPI
        if (cpiValidations.lengthType === 'fixed') {
          // disable the min and max 
          // set the values for min and max
          cpiMinLengthYupObject = yup.number()
            .typeError("Credit party identifier min length is required")
            .min(cpiMaxLengthSpec, "Credit party identifier length should be exactly " + cpiMaxLengthSpec)
            .max(cpiMaxLengthSpec, "Credit party identifier length should be exactly " + cpiMaxLengthSpec)
            .required("Credit party identifier min length is required")
            .default(cpiMaxLengthSpec);
          cpiMaxLengthYupObject = yup.number()
            .typeError("Credit party identifier max length is required")
            .min(cpiMaxLengthSpec, "Credit party identifier length should be exactly " + cpiMaxLengthSpec)
            .max(cpiMaxLengthSpec, "Credit party identifier length should be exactly " + cpiMaxLengthSpec)
            .required("Credit party identifier max length is required")
            .default(cpiMaxLengthSpec);

        } else {
          // set the range restrictions
          cpiMinLengthYupObject = yup.number()
            .typeError("Credit party identifier min length is required")
            .min(cpiMinLengthSpec, "Minimum value for credit party identifier length is " + cpiMinLengthSpec)
            .max(cpiMaxLengthSpec, "Minimum value for credit party identifier length is " + cpiMaxLengthSpec)
            .required("Credit party identifier min length is required")

          cpiMaxLengthYupObject = yup.number()
            .typeError("Credit party identifier max length is required")
            .min(yup.ref('cpiMinLength'), 'Cannot be less than min value')
            .max(cpiMaxLengthSpec, "Maximum value for credit party identifier length is  " + cpiMaxLengthSpec)
            .required("Credit party identifier max length is required")
        }

        // add dynamic validations for the length for the CPN
        if (cpnValidations.lengthType === 'fixed') {
          // disable the min and max 
          // set the values for min and max

          cpnMinLengthYupObject = yup.number()
            .typeError("Credit party name min length is required")
            .min(cpnMaxLengthSpec, "Credit party name length should be exactly " + cpnMaxLengthSpec)
            .max(cpnMaxLengthSpec, "Credit party name length should be exactly " + cpnMaxLengthSpec)
            .required("Credit party name min length is required")
            .default(cpnMaxLengthSpec);
          cpnMaxLengthYupObject = yup.number()
            .typeError("Credit party name max length is required")
            .min(cpnMaxLengthSpec, "Credit party name length should be exactly " + cpnMaxLengthSpec)
            .max(cpnMaxLengthSpec, "Credit party name length should be exactly " + cpnMaxLengthSpec)
            .required("Credit party name max length is required")
            .default(cpnMaxLengthSpec);

        } else {
          // set the range restrictions
          cpnMinLengthYupObject = yup.number()
            .typeError("Credit party name min length is required")
            .min(cpnMinLengthSpec, "Minimum value for credit party name length is " + cpnMinLengthSpec)
            .max(cpnMaxLengthSpec, "Minimum value for credit party name length is " + cpnMaxLengthSpec)
            .required("Credit party name min length is required")

          cpnMaxLengthYupObject = yup.number()
            .typeError("Credit party name max length is required")
            .min(yup.ref('cpnMinLength'), 'Cannot be less than min value')
            .max(cpnMaxLengthSpec, "Maximum value for credit party name length is  " + cpnMaxLengthSpec)
            .required("Credit party name max length is required")
        }

        // format check for the CPN and CPI
        const cpiFormatSpec = cpiValidations.format;
        const cpnFormatSpec = cpnValidations.format;

        let allowCPNNumbers = false;
        let allowCPNAlpha = false;
        let allowCPNSpecial = false;
        let allowCPNAnyChar = false;

        let allowCPINumbers = false;
        let allowCPIAlpha = false;
        let allowCPISpecial = false;
        switch (cpiFormatSpec) {
          case "ANS":
            allowCPIAlpha = true;
            allowCPINumbers = true;
            allowCPISpecial = true;
            break;
          case "AN":
            allowCPIAlpha = true;
            allowCPINumbers = true;
            allowCPISpecial = false;
            break;
          case "N":
            allowCPIAlpha = false;
            allowCPINumbers = true;
            allowCPISpecial = false;
            break;
          case "A":
            allowCPIAlpha = true;
            allowCPINumbers = false;
            allowCPISpecial = false;
            break;
          case "SP":
            allowCPIAlpha = false;
            allowCPINumbers = false;
            allowCPISpecial = true;
            break;
          default:
            toast.error("Unrecognized format " + cpiFormatSpec)
            break;
        }


        switch (cpnFormatSpec) {
          
          case "ANS":
            allowCPNNumbers = true;
            allowCPNAlpha = true;
            allowCPNSpecial = true;
            allowCPNAnyChar = false;
            break;
          case "AN":
            allowCPNNumbers = true;
            allowCPNAlpha = true;
            allowCPNSpecial = false;
            allowCPNAnyChar = false;
            break;
          case "N":
            allowCPNNumbers = true;
            allowCPNAlpha = false;
            allowCPNSpecial = false;
            allowCPNAnyChar = false;
            break;
          case "A":
            allowCPNNumbers = false;
            allowCPNAlpha = true;
            allowCPNSpecial = false;
            allowCPNAnyChar = false;
            break;
          case "SP":
            allowCPNNumbers = false;
            allowCPNAlpha = false;
            allowCPNSpecial = true;
            allowCPNAnyChar = false;
            break;
          case "S":
            allowCPNNumbers = true;
            allowCPNAlpha = true;
            allowCPNSpecial = true;
            allowCPNAnyChar = true;
            break;
          default:
            toast.error("Unrecognized format " + cpiFormatSpec)
            break;
        }

        // validation object definition
        createTemplateSchema = yup.object().shape({
          cpiLength: cpiMaxLengthYupObject,
          cpiMinLength: cpiMinLengthYupObject,
          cpnLength: cpnMaxLengthYupObject,
          cpnMinLength: cpnMinLengthYupObject,
          allowCPNNumbersSpec: yup.bool().oneOf([true, false]).default(allowCPNNumbers),
          allowCPNAlphaSpec: yup.bool().oneOf([true, false]).default(allowCPNAlpha),
          allowCPNSpecialSpec: yup.bool().oneOf([true, false]).default(allowCPNSpecial),
          allowCPNAnyCharSpec: yup.bool().oneOf([true, false]).default(allowCPNAnyChar),
          allowCPIAlphaSpec: yup.bool().oneOf([true, false]).default(allowCPIAlpha),
          allowCPINumbersSpec: yup.bool().oneOf([true, false]).default(allowCPINumbers),
          allowCPISpecialSpec: yup.bool().oneOf([true, false]).default(allowCPISpecial),
          market: yup.string().typeError("Market is required").required("Market is required"),
          language: yup.string().nullable()
            .when("market", {
              is: (market) => market !== "general",
              then: yup
                .string()
                .required("Language is required")
            }
            ),
          cpnFormatAZ: yup.boolean()
            .when(["allowCPNAlphaSpec", "allowCPNNumbersSpec", "allowCPNSpecialSpec"], {
              is: (allowCPNAlphaSpec, allowCPNNumbersSpec, allowCPNSpecialSpec) => allowCPNAlphaSpec && !allowCPNNumbersSpec && !allowCPNSpecialSpec,
              then: yup
                .bool()
                .oneOf([true], 'Field must be checked')
                .required("Required")
            }).when(["allowCPNAlphaSpec"], {
              is: (allowCPNAlphaSpec) => !allowCPNAlphaSpec,
              then: yup
                .bool()
                .oneOf([false], 'Field must not be checked')            
                .required("Required")
            }).test({
              name: "testAtLeastOneCPN",
              exclusive: true,
              params: {},
              message: "Select at least one",
              test: function (value) {
                return this.parent.cpnFormatSpecial || this.parent.cpnFormat09 || value
              },
            }),
          cpnFormat09: yup.boolean()
            .when(["allowCPNAlphaSpec", "allowCPNNumbersSpec", "allowCPNSpecialSpec"], {
              is: (allowCPNAlphaSpec, allowCPNNumbersSpec, allowCPNSpecialSpec) => !allowCPNAlphaSpec && allowCPNNumbersSpec && !allowCPNSpecialSpec,
              then: yup
                .bool()
                .oneOf([true], 'Field must be checked')
                .required("Required")
            }).when(["allowCPNNumbersSpec"], {
              is: (allowCPNNumbersSpec) => !allowCPNNumbersSpec,
              then: yup
                .bool()
                .oneOf([false], 'Field must not be checked')
                .required("Required")
            }).test({
              name: "testAtLeastOneCPN",
              exclusive: true,
              params: {},
              message: "Select at least one",
              test: function (value) {
                return this.parent.cpnFormatSpecial || this.parent.cpnFormatAZ || value
              },
            }),
          cpnFormatSpecial: yup.boolean()
            .when(["allowCPNAlphaSpec", "allowCPNNumbersSpec", "allowCPNSpecialSpec"], {
              is: (allowCPNAlphaSpec, allowCPNNumbersSpec, allowCPNSpecialSpec) => !allowCPNAlphaSpec && !allowCPNNumbersSpec && allowCPNSpecialSpec,
              then: yup
                .bool()
                .oneOf([true], 'Field must be checked')
                .required("Required")
            }).when(["allowCPNSpecialSpec"], {
              is: (allowCPNSpecialSpec) => !allowCPNSpecialSpec,
              then: yup
                .bool()
                .oneOf([false], 'Field must not be checked')
                .required("Required")
            }).test({
              name: "testAtLeastOneCPN",
              exclusive: true,
              params: {},
              message: "Select at least one",
              test: function (value) {
                return this.parent.cpnFormat09 || this.parent.cpnFormatAZ || value
              },
            }),
            cpnFormatAny: yup.boolean()
            .when(["allowCPNSpecialSpec"], {
              is: (allowCPNAnyCharSpec) => !allowCPNAnyCharSpec,
              then: yup
                .bool()
                .oneOf([false], 'Field must not be checked')
                .required("Required")
            }).test({
              name: "testAtLeastOneCPN",
              exclusive: true,
              params: {},
              message: "Select at least one",
              test: function (value) {
                return this.parent.cpnFormat09 || this.parent.cpnFormatAZ || this.parent.cpnFormatSpecial || value
              },
            }),
          cpiFormatAZ: yup.boolean().test({
            name: "testAtLeastOne",
            exclusive: false,
            params: {},
            message: "Select at least one",
            test: function (value) {
              return this.parent.cpiFormat09 || value
            },
          }),
          cpiFormat09: yup.boolean().test({
            name: "testAtLeastOne",
            exclusive: false,
            params: {},
            message: "Select at least one",
            test: function (value) {
              return this.parent.cpiFormatAZ || value
            },
          }),
          displayCPNInTemplate: yup.boolean(),
          additionalFields: yup
            .array()
            .of(
              yup
                .object()
                .nullable()
                .shape({
                  displayName: yup.string(),
                  mpaKey: yup.string(),
                  lengthMax: yup.number(),
                  lengthMin: yup.number(),
                  format: yup.string(),
                  required: yup.bool(),
                  defaultValue: yup.string().nullable(),
                  requiresUserInput: yup.boolean().default(false),
                  displayInTemplate: yup.boolean().default(false)
                })
            )
        });

        // if create flow add them directly
        let parsed = [];
        if (!isEditFlow) {
          otherParameters?.forEach(x => {
            const val = { ...x, displayInTemplate: false, requiresUserInput: (x.validation.required && x.defaultValue === null) };
            parsed.push(val);
          })
          reset({ ...getValues(), additionalFields: parsed });
        }
        showLoader(false);
        return otherParameters;
      } else {
        setMarketSelected(false);
        setDisableNextButton(true);
        setShowErrorMessage({ show: true, content: "Invalid QR Specification" })
        setDisableLanguage(true);
      }
    } catch (e) {
      setMarketSelected(false);
      setDisableNextButton(true);
      setShowErrorMessage({ show: true, content: "Invalid QR Specification" });
      setDisableLanguage(true);
    }
    showLoader(false);
  }

  const handleToggleShow = () => {
    setMarketSelected(false);
    setAnyCharSelected(false)
    setDisableLanguage(true);
    toggleShow();
    reset();
  };
  const cpnChanged = (value) => {
    setAnyCharSelected(value)
    if(value){
      setValue("cpnFormatSpecial", true)
      setValue("cpnFormat09", true)
      setValue("cpnFormatAZ", true)
    }
  };

  // Map format data to validation
  const mapValidation = (validation) => {
    let hasAlphabets = false;
    let hasNumbers = false;
    let hasSpecialChars = false;
    let hasAnyChars = false

    switch (validation.format) {
      
      case "ANS":
        hasAlphabets = true;
        hasNumbers = true;
        hasSpecialChars = true;
        break;
      case "AN":
        hasAlphabets = true;
        hasNumbers = true;
        break;
      case "A":
        hasAlphabets = true;
        break;
      case "N":
        hasNumbers = true;
        break;
      case "SP":
        hasSpecialChars = true;
        break;
      case "S":
        hasAlphabets = true;
        hasNumbers = true;
        hasSpecialChars = true;
        hasAnyChars = true;
        break;
      default:
    }
    
    return { lengthMax: validation.lengthMax, lengthMin: validation.lengthMin, lengthType: validation.lengthType, required: validation.required, format: { hasAlphabets, hasNumbers, hasSpecialChars, hasAnyChars } } 
  }

  const mapFunction = (x) => {
    let hasAlphabets = false;
    let hasNumbers = false;
    let hasSpecialChars = false;
    let hasAnyChars = false

    switch (x.format) {
      
      case "ANS":
        hasAlphabets = true;
        hasNumbers = true;
        hasSpecialChars = true;
        break;
      case "AN":
        hasAlphabets = true;
        hasNumbers = true;
        break;
      case "A":
        hasAlphabets = true;
        break;
      case "N":
        hasNumbers = true;
        break;
      case "SP":
        hasSpecialChars = true;
        break;
      case "S":
        hasAlphabets = true;
        hasNumbers = true;
        hasSpecialChars = true;
        hasAnyChars = true;
        break;
      default:
    }
    return { ...x, mpaFieldName: x.fieldName, validation: { length: x.lengthMax, minLength: x.lengthMin, format: { hasAlphabets, hasNumbers, hasSpecialChars, hasAnyChars } } }
  }

  const handleCreateTemplate = async (data, buttonType = "saveChanges") => {
    try {
      if (data.market === "general") {
        data.language = "NAN"
      }
      
      if (buttonType === "saveChanges" && isEditFlow) {
        // save changes flow
        showLoader(true);
        if (originalTemplate.validations.cpiLength !== data.cpiLength && !data.isDynamic) {
          alert("Since you have changed the length of Credit Party Identifier. You cannot save without editing the positions.");
          showLoader(false);
          return;
        } else if (originalTemplate.validations.cpnLength !== data.cpnLength && !data.isDynamic) {
          alert("Since you have changed the length of Credit Party Name. You cannot save without editing the positions.");
          showLoader(false);
          return;
        } else {
          const template = originalTemplate;
          template.market = data.market;
          template.validations.cpiLength = data.cpiLength;
          template.validations.cpnLength = data.cpnLength;
          template.validations.cpiMinLength = data.cpiMinLength;
          template.validations.cpnMinLength = data.cpnMinLength;
          template.language = data.language;
          template.validations.cpiFormat.hasAlphabets = data.cpiFormatAZ;
          template.validations.cpiFormat.hasNumbers = data.cpiFormat09;
          template.isDynamic = data.isDynamic;

          template.validations.cpnFormat.hasAlphabets = data.cpnFormatAZ;
          template.validations.cpnFormat.hasNumbers = data.cpnFormat09;
          template.validations.cpnFormat.hasSpecialChars = data.cpnFormatSpecial;
          template.validations.cpnFormat.hasAnyChars = data.cpnFormatAny;
          template.displayCPNInTemplate = data.displayCPNInTemplate;

          let removedFields = [];
          let newlyAddedFields = [];

          if (originalTemplate.displayCPNInTemplate === false && data.displayCPNInTemplate === true) {
            alert("Since you have Display In Template flag for Credit Party Name. You cannot save without adding the positions for its.");
            showLoader(false);
            return;
          }

          const additionalFields = data.additionalFields?.filter(x => x.displayInTemplate === true);
          // compare and find the differnces(Newly added fields)
          additionalFields?.forEach(field => {
            const index = template.additionalFields.findIndex(x => x.mpaKey === field.mpaKey && x.displayInTemplate === true);
            if (index === -1) {
              newlyAddedFields.push(field)
            }
          });

          // compare and find the differnces(Removed fields)
          template.additionalFields?.filter(x => x.displayInTemplate === true)?.forEach(field => {
            const index = additionalFields.findIndex(x => x.mpaKey === field.mpaKey && x.displayInTemplate === true);
            if (index === -1) {
              removedFields.push(field)
            }
          });

          const templateUserInputFields = template.additionalFields?.filter(x => x.requiresUserInput === true && x.displayInTemplate === false);
          const formUserInputFileds = data.additionalFields?.filter(x => x.requiresUserInput === true);

          
          // add or remove fields that needs user input but doesn't display on template
          formUserInputFileds?.forEach(field => {
            const index = templateUserInputFields.findIndex(x => x.mpaKey === field.mpaKey);
            if (index === -1) {
              // check if the template already contains a value 
              const templateIndex = template.additionalFields?.findIndex(x => x.mpaKey === field.mpaKey)
              if (templateIndex > -1) {
                template.additionalFields?.splice(templateIndex, 1);
              }

              let fieldValidation = field.validation;

              if(typeof field.validation.format === 'string' || field.validation.format instanceof String){
                const mappedField = mapFunction(field.validation);
                fieldValidation = mappedField.validation;
              }

              template.additionalFields?.push({
                mpaFieldName: field.mpaFieldName,
                mpaKey: field.mpaKey,
                helpText: field.mpaFieldName,
                configuration: field.configuration,
                requiresUserInput: true,
                displayInTemplate: field.displayInTemplate,
                validation: {
                  length : fieldValidation.lengthMax,
                  minLength : fieldValidation.lengthMin,
                  lengthType : fieldValidation.lengthType,
                  required: fieldValidation.required,
                  format: fieldValidation.format
                }
              })
            }
          });

          templateUserInputFields?.forEach(field => {
            const index = formUserInputFileds.findIndex(x => x.mpaKey === field.mpaKey);
            if (index === -1) {
              const removedIndex = template.additionalFields?.findIndex(x => x.mpaKey === field.mpaKey);
              if (removedIndex > -1) {
                template.additionalFields?.splice(removedIndex, 1);
              }
            }

          });
          // find fields that were completely removed (both requiresUserInput and displayInTemplate false)
          const unselectedFields = data.additionalFields?.filter(x => x.displayInTemplate === false && x.requiresUserInput === false);
          const fullyRemovedFields = [];
          template.additionalFields?.forEach(x => {
            const index = unselectedFields.findIndex(k => k.mpaKey === x.mpaKey);
            // if found completely unselected 
            if (index > -1) {
              fullyRemovedFields.push(x);
            }
          })

          fullyRemovedFields.forEach(x => {
            const index = template.additionalFields?.findIndex(y => y.mpaKey === x.mpaKey);
            if (index > -1) {
              template.additionalFields?.splice(index, 1);
            }
          })

          template.removedFields = removedFields;
          template.newlyAddedFields = newlyAddedFields;


          if (newlyAddedFields.length > 0) {
            alert("Since you have added new fields to display on template. You cannot save without editing the positions.");
            showLoader(false);
            return;
          }
          try {
            if (data.isDynamic) {
              await updateDynamicTemplate(template);
              toggleShow();
              showLoader(false);
              toast.success("Template updated successfully");
            } else {
              await updateTemplate(template);
              toggleShow();
              showLoader(false);
              toast.success("Template updated successfully");
            }
          } catch (e) {
            toast.error("Failed to update template");
            showLoader(false);
          }
          await getTemplateList(selectedMarket)
          showLoader(false);
        }
      } else {
        // next and edit positions flow
        // map format to variables
        const additionalParams = data.additionalFields?.filter(x => x.displayInTemplate === true || x.requiresUserInput === true).map(mapFunction)
        let removedFields = [];
        let newlyAddedFields = [];
        let fullyRemovedFields = [];

        if (isEditFlow) { 
          const template = originalTemplate;
          
          const additionalFields = data.additionalFields?.filter(x => x.displayInTemplate === true && x.requiresUserInput === true);
          // compare and find the differnces(Newly added fields)
          additionalFields?.forEach(field => {
            const index = template.additionalFields?.findIndex(x => x.mpaKey === field.mpaKey && x.displayInTemplate === true);
            if (index === -1) {
              newlyAddedFields.push(field)
            }
          });

          // compare and find the differnces(Removed fields)
          template.additionalFields?.filter(x => x.displayInTemplate === true)?.forEach(field => {
            const index = additionalFields?.findIndex(x => x.mpaKey === field.mpaKey && x.displayInTemplate === true);
            if (index === -1) {
              removedFields.push(field)
            }
          });

          // find fields that were completely removed (both requiresUserInput and displayInTemplate false)
          const unselectedFields = data.additionalFields?.filter(x => x.displayInTemplate === false && x.requiresUserInput === false);

          template.additionalFields?.forEach(x => {
            const index = unselectedFields.findIndex(k => k.mpaKey === x.mpaKey);
            // if found completely unselected 
            if (index > -1) {
              fullyRemovedFields.push(x);
            }
          })

          // remove the fields duplicates
          newlyAddedFields?.forEach(field => {
            const index = additionalParams.findIndex(x => x.mpaKey === field.mpaKey);
            if (index > -1) {
              additionalParams.splice(index, 1);
            }
          })
        }
        setCreateTemplateData({ ...data, additionalFields: additionalParams, removedFields, newlyAddedFields, fullyRemovedFields });

        const validations = {
          cpiLength: data.cpiLength,
          cpnLength: data.cpnLength,
          cpiMinLength: data.cpiMinLength,
          cpnMinLength: data.cpnMinLength,
          cpiFormat: {
            hasAlphabets: data.cpiFormatAZ,
            hasNumbers: data.cpiFormat09,
          },
          cpnFormat: {
            hasAlphabets: data.cpnFormatAZ,
            hasNumbers: data.cpnFormat09,
            hasSpecialChars: data.cpnFormatSpecial,
            hasAnyChars: data.cpnFormatAny
          }
        }
        // if a dynamic template skip step 2 (editor)
        if (data.market === "general" && data.isDynamic) {
          // open the save template modal
          toggleShow();
          openSaveModal(false);
        } else if (data.market === "general" && data.isDynamic && isEditFlow) {
          // open the save template modal
          toggleShow();
          openSaveModal(true);
        } else {
          initializeEditor(data.cpiLength, data.cpnLength, validations, additionalParams, removedFields, newlyAddedFields, data.displayCPNInTemplate);
          history.push("/templates/editor");
        }
      }
    } catch (e) {
      showLoader(false);
      console.log(e)
      if (e.response) {
        console.log(e.response);
      } else {
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggleShow}
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="modal-template"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            {isEditFlow ? "Edit" : "Create"} Template
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit((data) => handleCreateTemplate(data, "next"))} >
          <Modal.Body>
            {showErrorMessage.show &&
              <Alert variant="danger">
                {showErrorMessage.content}
              </Alert>}
            <Form.Group className="mb-3" controlId="market">
              <Form.Label>Market *</Form.Label>
              <Form.Select
                data-testid="market"
                {...register("market", { required: true })}
                aria-label="Select a Market"
                disabled={disabledMarket}
                isInvalid={errors.market}
              >
                <option value="">Select Market</option>
                {marketOptions.map(market => {
                  return <option key={market.value} value={market.value}>{market.displayName}</option>
                })}
                <option value="general">General</option>
              </Form.Select>
              <Form.Control.Feedback id="marketErrors" type="invalid">
                {errors.market?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {watch("market") === "general" &&
              <Form.Group className="mb-3" controlId="isDynamic">
                <Form.Check className="py-1">
                  <Form.Check.Input data-testid="isDynamic" id="isDynamic" isInvalid={errors.isDynamic} {...register("isDynamic")} disabled={disableIsDynamicCheckBox} />
                  <Form.Check.Label htmlFor="isDynamic" className="px-2">Dynamic Template</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.isDynamic?.message}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>}

            <Form.Group className="mb-4" controlId="language">
              <Form.Label>Language *</Form.Label>
              <Form.Select
                data-testid="language"
                {...register("language")}
                aria-label="Select a Language"
                isInvalid={errors.language}
                disabled={disableLanguage || watch("market") === "general"}
              >
                <option value="">Select Language</option>
                {langageOptions.map(language => <option key={language.id} value={language.shortCode3}>{language.name}</option>)}
              </Form.Select>
              <Form.Control.Feedback id="marketErrors" type="invalid">
                {errors.language?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {marketSelected && (
              <>
                <Row>
                  <Col>
                    <div className="card p-3 template-cpi-container">
                      <Form.Group className="mb-3 " controlId="cpiLength">
                        <Form.Label>Credit Party Identifier *</Form.Label>
                        <Row>
                          <Col sm="6" md="6" lg="6">
                            <Form.Control
                              data-testid="cpiMinLength"
                              type="number"
                              {...register("cpiMinLength")}
                              autoComplete="off"
                              placeholder="Min Length"
                              min={1}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              isInvalid={errors.cpiMinLength}
                            />
                            <Form.Control.Feedback id="cpiMinLengthErrors" type="invalid">
                              {errors.cpiMinLength?.message}
                            </Form.Control.Feedback>
                          </Col>

                          <Col sm="6" md="6" lg="6">
                            <Form.Control
                              data-testid="cpiLength"
                              type="number"
                              {...register("cpiLength")}
                              autoComplete="off"
                              placeholder="Max Length"
                              min={1}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              isInvalid={errors.cpiLength}
                            />
                            <Form.Control.Feedback id="cpiLengthErrors" type="invalid">
                              {errors.cpiLength?.message}
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="cpiFormat">
                        <Form.Label>Format *</Form.Label>

                        <Form.Check className="py-1">
                          <Form.Check.Input data-testid="cpiFormatAZ" id="cpiFormatAZ" isInvalid={errors.cpiFormatAZ || errors.cpiFormat} {...register("cpiFormatAZ")} />
                          <Form.Check.Label htmlFor="cpiFormatAZ" className="px-2"> A-Z letters</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpiFormatAZ?.message}
                          </Form.Control.Feedback>
                        </Form.Check>

                        <Form.Check className="py-1">
                          <Form.Check.Input data-testid="cpiFormat09" id="cpiFormat09" isInvalid={errors.cpiFormat09 || errors.cpiFormat} {...register("cpiFormat09")} />
                          <Form.Check.Label htmlFor="cpiFormat09" className="px-2"> 0-9 numbers</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpiFormat09?.message}

                          </Form.Control.Feedback>
                        </Form.Check>
                        {errors.cpiFormat?.message}
                        <Form.Control.Feedback id="cpiFormatErrors" type="invalid">
                          {errors.cpiFormat?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="invalid-feedback" id="cpiFormatErrors">
                        {errors.cpiFormat?.message}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="card p-3">
                      <Form.Group className="mb-3" controlId="cpnLength">
                        <Form.Label>Credit Party Name *</Form.Label>
                        <Row>
                          <Col sm="6" md="6" lg="6">
                            <Form.Control
                              data-testid="cpnMinLength"
                              type="number"
                              {...register("cpnMinLength")}
                              autoComplete="off"
                              placeholder="Min Length"
                              min={1}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              isInvalid={errors.cpnMinLength}
                            />
                            <Form.Control.Feedback id="cpnMinLengthErrors" type="invalid">
                              {errors.cpnMinLength?.message}
                            </Form.Control.Feedback>
                          </Col>
                          <Col sm="6" md="6" lg="6">
                            <Form.Control
                              data-testid="cpnLength"
                              type="number"
                              {...register("cpnLength", { required: true })}
                              autoComplete="off"
                              placeholder="Max Length"
                              min={1}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              isInvalid={errors.cpnLength}
                            />

                            <Form.Control.Feedback id="cpnLengthErrors" type="invalid">
                              {errors.cpnLength?.message}
                            </Form.Control.Feedback>
                          </Col>
                        </Row>

                      </Form.Group>

                      <Form.Group className="mb-3" controlId="displayCPNInTemplateGroup" >
                        {watch("market") !== "general" ?
                          <Form.Check className="py-1" >
                            <Form.Check.Input data-testid="displayCPNInTemplate" id="displayCPNInTemplate" {...register("displayCPNInTemplate")} />
                            <Form.Check.Label htmlFor="displayCPNInTemplate" className="px-2">Display In Template</Form.Check.Label>
                          </Form.Check>
                          : <>
                            <Form.Control
                              type="hidden"
                              name={`displayCPNInTemplate`}
                              {...register("displayCPNInTemplate")}
                              value={true}
                            />
                            <Form.Check className="py-1" >
                              <Form.Check.Input disabled={true} checked={true} data-testid="displayCPNInTemplate" id="displayCPNInTemplate" />
                              <Form.Check.Label htmlFor="displayCPNInTemplate" className="px-2">Display In Template</Form.Check.Label>
                            </Form.Check>
                          </>
                        }
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="cpnFormat">
                        <Form.Label>Format *</Form.Label>

                        <Form.Check className="py-1">
                          <Form.Check.Input data-testid="cpnFormatAZ" id="cpnFormatAZ" isInvalid={errors.cpnFormatAZ || errors.cpnFormat} {...register("cpnFormatAZ")} disabled={anyCharSelected}/>
                          <Form.Check.Label htmlFor="cpnFormatAZ" className="px-2"> A-Z letters</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpnFormatAZ?.message}
                          </Form.Control.Feedback>
                        </Form.Check>

                        <Form.Check className="py-1">
                          <Form.Check.Input data-testid="cpnFormat09" id="cpnFormat09" isInvalid={errors.cpnFormat09 || errors.cpnFormat}    {...register("cpnFormat09")} disabled={anyCharSelected}/>
                          <Form.Check.Label htmlFor="cpnFormat09" className="px-2">0-9 numbers</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpnFormat09?.message}
                          </Form.Control.Feedback>
                        </Form.Check>

                        <Form.Check className="py-1">
                          <Form.Check.Input id="cpnFormatSpecial" isInvalid={errors.cpnFormatSpecial || errors.cpnFormat} {...register("cpnFormatSpecial")} disabled={anyCharSelected}/>
                          <Form.Check.Label htmlFor="cpnFormatSpecial" className="px-2">Special Characters</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpnFormatSpecial?.message}
                          </Form.Control.Feedback>
                        </Form.Check>
                        {watch("market") !== "general" &&
                        <Form.Check className="py-1">
                          <Form.Check.Input id="cpnFormatAny" isInvalid={errors.cpnFormatAny || errors.cpnFormat} {...register("cpnFormatAny")} 
                            onChange={(evt) =>( cpnChanged(evt.target.checked))}/>
                          <Form.Check.Label htmlFor="cpnFormatAny" className="px-2">Any Characters</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.cpnFormatAny?.message}
                          </Form.Control.Feedback>
                        </Form.Check>}
                        <div className="invalid-feedback" id="cpnFormatErrors">
                          {errors.cpnFormat?.message}
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>

                {watch("market") !== "general" &&
                  <div className="card p-3 mt-2">
                    <Form.Group className="mb-3" controlId="cpnLength">
                      <Form.Label>Additional Field Options</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3 additional-fields-container" controlId="additionalFields">
                      <Table>
                        <thead>
                          <tr>
                            <th>Field Name</th>
                            <th className="text-center">User Input</th>
                            <th className="text-center">Display in Template</th>
                          </tr>
                        </thead>
                        <tbody>
                          {controlledFields.map((field, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {field.fieldName}
                                </td>
                                <td className="text-center">
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]displayName`}
                                    {...register(`additionalFields.${index}.displayName`)}
                                    value={field.fieldName}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]mpaKey`}
                                    {...register(`additionalFields.${index}.mpaKey`)}
                                    value={field.parentFieldKey}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]lengthMin`}
                                    {...register(`additionalFields.${index}.lengthMin`)}
                                    value={field.validation.lengthMin}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]lengthMax`}
                                    {...register(`additionalFields.${index}.lengthMax`)}
                                    value={field.validation.lengthMax}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]format`}
                                    {...register(`additionalFields.${index}.format`)}
                                    value={field.validation.format}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]required`}
                                    {...register(`additionalFields.${index}.required`)}
                                    value={field.validation.required === null || !field.validation.required ? false : true}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name={`additionalFields[${index}]defaultValue`}
                                    {...register(`additionalFields.${index}.defaultValue`)}
                                    value={field.defaultValue === null ? "" : field.defaultValue}
                                  />

                                  <Form.Check className="py-1">
                                    <Form.Check.Input data-testid={`additionalFields-userInput-${index}`} disabled={field.validation.required && field.defaultValue === null} id={`additionalFields-userInput-${index}`} {...register(`additionalFields.${index}.requiresUserInput`)} />
                                  </Form.Check>
                                </td>
                                <td className="text-center">
                                  <Form.Check className="py-1">
                                    <Form.Check.Input disabled={watch(`additionalFields[${index}].requiresUserInput`) !== true} data-testid={`additionalFields-display-${index}`} id={`additionalFields-display-${index}`} {...register(`additionalFields.${index}.displayInTemplate`)} />
                                  </Form.Check>
                                </td>
                              </tr>)
                          })}

                        </tbody>
                      </Table>
                    </Form.Group>
                  </div>}
              </>)}
          </Modal.Body >
          <Modal.Footer className="d-flex align-items-center justify-content-between flex-row">
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={() => handleToggleShow()}
            >
              Cancel
            </Button>
            <div className="d-flex align-items-center justify-content-between">
              {
                isEditFlow && <Button variant="primary" name="saveChanges" onClick={handleSubmit((data) => handleCreateTemplate(data))} disabled={disableNextButton} data-testid="btnSave">
                  Save Changes
                </Button>
              }

              <Button variant="primary" type="submit" name="next" data-testid="btnNext" className="ms-3" disabled={disableNextButton}>
                {isEditFlow && watch("isDynamic") ? "Edit Details" : isEditFlow && !watch("isDynamic") ? "Edit Positions" : "Next"}
              </Button>
            </div>
          </Modal.Footer >
        </Form >
      </Modal >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: state.profile.groups,
    market: state.profile.market,
    languages: state.language.list,
    isSuperAdmin: state.permission.isSuperAdmin,
    additionalParams: state.qrSpecifiction.additionalParams,
    marketOptions: state.profile.markets
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCreateTemplateData: (data) => dispatch(setCreateTemplateDetails(data)),
  getTemplate: (templateId) => dispatch(getTemplate(templateId)),
  showLoader: (data) => dispatch(showLoader(data)),
  getTemplateList: (market) => dispatch(getTemplates(market)),
  updateTemplate: (data) => dispatch(updateTemplate(data)),
  updateDynamicTemplate: (data) => dispatch(updateDynamicTemplate(data)),
  getQRFieldValidations: (market, isTemplateCreate) => dispatch(getQRFieldValidations(market, isTemplateCreate)),
  receiveMarketData: () => dispatch(receiveMarketData())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTempateModal);
